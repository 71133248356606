// React imports
// This is for React 18 but does not work with Cypress:
// import { hydrateRoot } from 'react-dom/client'

// Remix imports
import { RemixBrowser } from '@remix-run/react'

// Third party imports
// import * as Sentry from '@sentry/remix'

// Generated imports

// App imports

// if (window.ENV.SENTRY_DSN) {
//   Sentry.init({
//     dsn: window.ENV.SENTRY_DSN,
//     environment: `client-${window.ENV.SENTRY_ENVIRONMENT}`,
//     tracesSampleRate: 1,
//     integrations: [
//       new Sentry.BrowserTracing({
//         routingInstrumentation: Sentry.remixRouterInstrumentation(
//           useEffect,
//           useLocation,
//           useMatches
//         ),
//       }),
//     ],
//     // ...
//   })
// }

require('react-dom').hydrate(<RemixBrowser />, document)

// This is for React 18 but does not work with Cypress
// hydrateRoot(document, <RemixBrowser />)
